.create_ticket{
  .date-picker{
    width: fit-content !important;
  }
}
.activity_information_section{
  .image_container {
    width: 200px;
    height: 200px;
    min-width: 200px;
    min-height: 200px;
    border-radius: 8px 0px 0px 8px;
    .image {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 8px 0px 0px 8px;
    }
  }
  .divider {
    width: 1px;
    height: 20px;
    background-color: $pink-light;
  }


}
.edit-btn {
  transition: all 0.5s;
  &:hover{
    background-color: $pink !important;
 
    i {
      color: $white !important;
    }
  }
}
.create-campaign{
  width: 100%;
  .profile-avatar-upload{
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    img{
      width: 100%;
      height: 100%;
    }
  }
}