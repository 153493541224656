.react-select-disabled {
  .react-select__control {
    height: 46px !important;
    min-height: 46px !important;
    max-height: 46px !important;
    border: 1px solid #E4E8EB !important;
    background-color: #E9ECEF !important;
    border-radius: 20px !important;
  
    &.react-select__control--is-focused,
    &:hover {
      border: 1px solid $primary !important;
      box-shadow: none;
    }
  }
}
.react-select {
  width: 100%;
  height: 44px;
  max-height: 42px;
  font-size: 18px;
  .react-select__menu{
    border-radius: 10px;
    .react-select__option{
      border-radius: 10px;
      font-size: 12px;
      font-weight: 400;
    }
    .react-select__menu-notice{
      font-size: 14px;
      color: $muted;
    }
  }
}

.react-select__control {
  height: 46px !important;
  min-height: 46px !important;
  max-height: 46px !important;
  border: 1px solid #E4E8EB !important;
  background-color: #ffff !important;
  border-radius: 20px !important;

  &.react-select__control--is-focused,
  &:hover {
    border: 1px solid $primary !important;
    box-shadow: none;
  }
}

.react-select__value-container {
  padding-left: 10px !important;
  display: flex !important;
  flex-wrap: nowrap !important;
  overflow-x: hidden !important;
}

.react-select__input {
  color: $dark !important;
  font-size: 12px !important;
}

.react-select__placeholder {
  font-size: 14px !important; 
  font-weight: 400;
  color: #798188 !important;
}
.react-select__single-value {
  color: $content !important;
  font-size: 14px;
}

.react-select__option {
  &.react-select__option--is-focused {
    background: rgba(47, 85, 212, 0.1);
  }

  &.react-select__option--is-selected {
    background: $primary;
  }
}
.react-select__indicator-separator {
  margin-top: 7px !important;
}
.css-6j8wv5-Input {
  padding-bottom: 0 !important;
  margin: 0 !important
}


 

 
