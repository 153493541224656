

.profile-avatar-upload {
  position: relative;
  overflow: hidden;

  &:before {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      border-radius: 0;
      opacity: 0.8;
      transition: 0.5s ease;
      width: 100%;
      height: 40px;
      bottom: 0;
      left: 0;
      background-color: $primary;
      color: #cebfe9;

  }

  span {
      font-size: 0;
  }

  &:hover {
      span {
          font-size: 14px;
          position: absolute;
          top: 120px;
          padding: 0;
          color: #c8badc;

      }
  }

  &.profile-avatar-upload-sm {
      &:before {
          height: 28px;
      }
  }

  &:hover {
      &:before {
          font-size: 30px;
          height: 100%;
          background-color: $primary-light;
      }
  }

  .photo-upload {
      width: 100%;
      height: 100%;
      opacity: 0;
      position: absolute;
      cursor: pointer;

  }
}
.create-store-img {
  width: 100%;
  min-height: 230px;
  max-height: 230px;
  position: relative;
  text-align: center;
  border-radius: 6px;
  background-color: #fff;

}

.create-store-input {
  opacity: 0;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}


.store-type-container {
  display: flex;
  margin-top: 20px;
  .store-type-wrapper {
      display: flex;
      position: relative;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

    }
    
    .store-type-wrapper input {
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    .checkmark {
      top: 0;
      left: 0;
      height: 60px;
      width: 220px;
      background-color: transparent;
      border: 1px solid $muted;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 12px;
      font-weight: 500;
       border-radius: 50px !important;
      .checkmark-icon{
        background-color: $pink;
        border-radius: 50px;
        width: 40px;
        min-width: 40px;
        min-height: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        font-size: 20px;
       i{
        color: $white;
       } 

       &:hover{

       }
      
      }
    }
  
    .store-type-wrapper input:checked ~ .checkmark {
     border: 1.5px solid $primary
     
    }
    
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
    
    .store-type-wrapper input:checked ~ .checkmark:after {
      display: block;
    }
    
    .store-type-wrapper .checkmark:after {
      left: 2px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: none !important;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
}
.custom-padding {
  padding: 0.375rem 2.5rem !important;
}

.input-default-value {
  position: absolute;
  margin-left: 15px;
  margin-top: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: 'none';
  color: #000000;
}
.business-type {
  font-weight: 600;
}

.business-create{
  .container {
    max-width: 1440px !important;
    width: 90% !important;
  }
}
.edit-place {
  .menu-create{
    input[type=file] {
      width: 100% !important;
    }
  }
}

.menu-create {
  input[type=file] {
      width: 488.5px;
      max-width: 100%;
      color: #444;
      padding: 0px;
      border: none;
      font-size: 14px;
      font-weight: 600;
      background-clip: padding-box !important;
      background-color: #f8f9fa !important;
      border: 1px solid #dedede !important;
      border-radius: 20px !important;
      display: block;
  }

  input[type=file]::file-selector-button {
      margin-right: 20px;
      border: none;
      background: transparent;
      padding: 10px 10px;
      border-radius: 20px;
      color: #444;
      cursor: pointer;
      transition: background .2s ease-in-out;
      border-radius: 20px 0px 0px 20px;
  }

  input[type=file]::file-selector-button:hover {
      background: rgb(217, 216, 216);

  }
}

@media (max-width: 992px) {
  .container {
    width: 100%;
  }
.store-type-container {
flex-wrap: wrap;
.card-body {
  padding: 0 !important;
}
.store-type-wrapper {
    font-size: 14px;
    width: 100% !important;
    .form-check {
      width: 100% !important;

    }
  }
  .checkmark {
    height: 45px;
    width: 100% !important;
  }

}

}



.image-slider {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.image-slider button {
  background: none;
  border: none;
  font-size: 24px;
  padding: 10px;
  cursor: pointer;
  color: white;
  text-shadow: 1px 1px 2px black;
}

.image-container {
  width: 325px;
  height: 250px;
  min-height: 250px;
  overflow: hidden; 
  display: flex; 
  justify-content: center;
  align-items: center; 
  position: relative;
  .image {
    object-fit: cover;
    object-position: center; 
    width: 100%; 
    height: 100%; 
  }
  .overlay {
    position: absolute; 
  top: 0;
  left: 0;
  width: 100%; 
  height: 100%; 
  background: rgba(0, 0, 0, 0.2); 
 
  }
}

  
.slider-dots {
  position: absolute;
  bottom: 0;
}
.slider-indicators {
  display: flex;
  padding: 10px;
}

.indicator {
  cursor: pointer;
  height: 7px;
  width: 7px;
  margin: 5px;
  background-color: gray;
  border-radius: 50%;
  display: inline-block;
}

.indicator.active {
  background-color: white;
}

.info_container{
  padding: 8px;
  .place_title{
    font-size: 14px;
    font-weight: 600;
  }
  .place_description {
    word-wrap: break-word; 
  overflow-wrap: break-word;
  font-size: 12px;
  font-weight: 400;
  color: $primary;
  }
}
.business_info{
  .address_text{
    word-wrap: break-word; 
    overflow-wrap: break-word;
  }
}

