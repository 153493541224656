//Buttons
@each $name,
$value in $colors {
  .btn-#{$name} {
    background-color: #{$value} !important;
    border: 1px solid #{$value} !important;
    color: $white !important;

    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus {
      background-color: darken($value, 10%) !important;
      border-color: darken($value, 10%) !important;
      color: $white !important;
    }
  }

  .btn-soft-#{$name} {
    background-color: rgba($value, 0.1) !important;
    border: 1px solid rgba($value, 0.1) !important;
    color: #{$value} !important;

    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus {
      background-color: #{$value} !important;
      border-color: #{$value} !important;
      color: $white !important;
    }
  }

  .btn-outline-#{$name} {
    border: 1px solid #{$value};
    color: #{$value};
    background-color: transparent;

    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus,
    &:not(:disabled):not(.disabled):active {
      background-color: #{$value};
      border-color: #{$value};
      color: $white !important;
    }
  }

  .btn-focus-none {
    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }


}

.btn {
  padding: 10.5px 30px !important;
  border-radius: 20px ;
  outline: none;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s;

  &.btn-corner-right {
    border-radius: 0px 8px 0px 8px !important;
  }

  &.btn-corner-left {
    border-radius: 8px 0px 8px 0px !important;
  }
  &.btn-corner-left-top {
    border-radius: 0px 0px 8px 0px !important;
  }

  &:focus {
    box-shadow: none !important;
  }

  &.btn-lg {
    padding: 14px 35px !important;
    font-size: 14px;
  }

  &.btn-md {
    padding: 9px 30px !important;
    font-size: 14px;
  }

  &.btn-sm {
    padding: 8px 20px !important;
    font-size: 12px !important;
  }

  &.btn-xs {
    padding: 6px 15px !important;
    font-size: 10px !important;
  }


  &.btn-pills {
    border-radius: 100px;
  }
  &.btn-semi-pills{
    border-radius: 8px;
  }

  &.btn-light {
    color: $dark !important;
    border: 1px solid $gray-300 !important;

    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus {
      background-color: darken($light, 10%) !important;
      color: $dark !important;
    }
  }

  &.btn-soft-light {
    color: rgba($dark, 0.5) !important;
    border: 1px solid $gray-300 !important;

    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus {
      color: $dark !important;
    }
  }

  &.btn-outline-light {
    border: 1px solid $gray-300 !important;
    color: $dark !important;
    background-color: transparent;

    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus {
      background-color: $light !important;
    }
  }

  &.btn-icon {
    height: 36px;
    width: 36px;
    line-height: 34px;
    padding: 0;
    color: black !important;
    border: none !important;

    .icons {
      height: 16px;
      width: 16px;
      font-size: 16px;
    }

    &.btn-xsm {
      height: 26px;
      width: 26px;
      line-height: 0px;

      .icons {
        height: 14px;
        width: 14px;
        font-size: 14px;
      }
    }

    &.btn-sm {
      height: 30px;
      width: 30px;
      line-height: 28px;
      font-size: 14px;
    }

    &.btn-lg {
      height: 48px;
      width: 48px;
      line-height: 46px;

      .icons {
        height: 20px;
        width: 20px;
        font-size: 20px;
      }
    }
  }
}

button:not(:disabled) {
  outline: none;
}

//Badge
@each $name,
$value in $colors {
  .badge-#{$name} {
    background-color: #{$value} !important;
    border: 1px solid #{$value} !important;
    color: $white !important;
    display: flex !important;
    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus {
      background-color: darken($value, 10%) !important;
      border-color: darken($value, 10%) !important;
      color: $white !important;
    }
  }

  .badge-soft-#{$name} {
    background-color: rgba($value, 0.1) !important;
    border: 1px solid rgba($value, 0.1) !important;
    color: #{$value} !important;

    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus {
      background-color: #{$value} !important;
      border-color: #{$value} !important;
      color: $white !important;
    }
  }

  .badge-outline-#{$name} {
    border: 1px solid #{$value};
    color: #{$value};
    background-color: transparent;

    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus,
    &:not(:disabled):not(.disabled):active {
      background-color: #{$value};
      border-color: #{$value};
      color: $white !important;
    }
  }

}

.badge {
  padding: 10.5px 30px !important;
  border-radius: 20px ;
  outline: none;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s;

  &.badge-corner-right {
    border-radius: 0px 8px 0px 8px !important;
  }

  &.badge-corner-left {
    border-radius: 8px 0px 8px 0px !important;
  }
  &.badge-corner-left-top {
    border-radius: 0px 0px 8px 0px !important;
  }

  &:focus {
    box-shadow: none !important;
  }

  &.badge-lg {
    padding: 14px 35px !important;
    font-size: 14px;
  }

  &.badge-md {
    padding: 9px 30px !important;
    font-size: 14px;
  }

  &.badge-sm {
    padding: 8px 20px !important;
    font-size: 12px !important;
  }

  &.badge-xs {
    padding: 6px 15px !important;
    font-size: 10px !important;
  }

  &.badge-dotted {
    padding: 0 !important;
    width: 25px;
    min-width: 25px;
    min-height: 25px;
    height: 25px;
    
  }


  &.badge-pills {
    border-radius: 100px;
  }
  &.badge-semi-pills{
    border-radius: 8px;
  }

  &.badge-light {
    color: $dark !important;
    border: 1px solid $gray-300 !important;

    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus {
      background-color: darken($light, 10%) !important;
      color: $dark !important;
    }
  }

  &.badge-soft-light {
    color: rgba($dark, 0.5) !important;
    border: 1px solid $gray-300 !important;

    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus {
      color: $dark !important;
    }
  }

  &.badge-outline-light {
    border: 1px solid $gray-300 !important;
    color: $dark !important;
    background-color: transparent;

    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus {
      background-color: $light !important;
    }
  }

  &.badge-icon {
    height: 36px;
    width: 36px;
    line-height: 34px;
    padding: 0;
    color: black !important;
    border: none !important;

    .icons {
      height: 16px;
      width: 16px;
      font-size: 16px;
    }

    &.badge-xsm {
      height: 26px;
      width: 26px;
      line-height: 0px;

      .icons {
        height: 14px;
        width: 14px;
        font-size: 14px;
      }
    }

    &.badge-sm {
      height: 30px;
      width: 30px;
      line-height: 28px;
      font-size: 14px;
    }

    &.badge-lg {
      height: 48px;
      width: 48px;
      line-height: 46px;

      .icons {
        height: 20px;
        width: 20px;
        font-size: 20px;
      }
    }
  }
}

//Pulse Animation
@each $name,
$value in $colors {
  .pulse-#{$name} {
    background-color: #{$value} !important;
    border: 1px solid #{$value} !important;
    color: $white !important;
    display: flex !important;
    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus {
      background-color: darken($value, 10%) !important;
      border-color: darken($value, 10%) !important;
      color: $white !important;
    }
  }

}
.pulse {
  width: 20px;  
  height: 20px; 
  border-radius: 50%; 
  position: relative;
  animation: pulse-animation 2s infinite; 
}

@keyframes pulse-animation {
  0% {
    transform: scale(1); 
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1); 
    opacity: 1; 
  }
}


.form-control-error-label {
  font-size: 10px;
  line-height: 13px;
  color: $red;
  margin-bottom: -5px;
  display: block;
  margin-top: 5px;
}


//Radio  Group

.form-check-input {
  &:focus {
    border-color: $primary;
    box-shadow: none;
  }

  &.form-check-input:checked {
    background-color: $primary;
    border-color: $primary;
  }
}

//Form File Input
.form__form-group-label{
  font-weight: 500;
  font-size: 16px;
}
.form-file {
  width: 100%;
  min-height: 100px;
  position: relative;
  text-align: center;
  background-color: #fff;


  .form-file-control {
    opacity: 0;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
  }


}
// Avatar


.avatar {
  &.avatar-xxxl {
    height: 300px;
    min-height: 300px;
    max-width: 300px;
    max-height: 300px;
    border-radius: 20px;
  }
  &.avatar-store {
    height: 150px;
    min-height: 150px;
    max-width: 150px;
    max-height: 150px;
    border-radius: 5px;
  }

  &.avatar-xxl {
    height: 150px;
    width: 150px;
    min-height: 150px;
    max-width: 150px;
    max-height: 150px;
    border-radius: 150px;
  }

  &.avatar-xl {
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
    max-width: 100px;
    max-height: 100px;
  }

  &.avatar-lg {
    width: 75px;
    height: 75px;
    min-width: 75px;
    min-height: 75px;
    max-width: 75px;
    max-height: 75px;
  }

  &.avatar-md {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    max-width: 50px;
    max-height: 50px;
  }
  &.square-avatar-xl {
    width: 125px;
    height: 145px;
    min-width: 125px;
    min-height: 145px;
    max-width: 125px;
    max-height: 145px;
    border-radius: 6px;
    img {
      position: relative;
      top: 12px;
      left: 2px;
    }
  }
  &.square-avatar-xxl {
    width: 210px;
    height: 245px;
    min-width: 210px;
    min-height: 245px;
    max-width: 210px;
    max-height: 245px;
    border-radius: 20px;

  }
  &.square-avatar-3xl {
    width: 250px;
    height: 285px;
    min-width: 250px;
    min-height: 285px;
    max-width: 250px;
    max-height: 285px;
    border-radius: 20px;

  }
  &.square-avatar-lg {
    width: 75px;
    height: 95px;
    min-width: 75px;
    min-height: 95px;
    max-width: 75px;
    max-height: 95px;
    border-radius: 6px;

    img {
      position: relative;
      top: 12px;
      left: 2px;
    }
  }

  &.square-avatar-md {
    width: 65px;
    height: 75px;
    min-width: 65px;
    min-height: 75px;
    max-width: 65px;
    max-height: 75px;
    border-radius: 6px;

    img {
      position: relative;
      top: 10px;
      left: 4px;
    }
  }
  &.square-avatar-sm {
    width: 55px;
    height: 65px;
    min-width: 55px;
    min-height: 65px;
    max-width: 55px;
    max-height: 65px;
    border-radius: 6px;

    img {
      position: relative;
      top: 10px;
      left: 4px;
    }
  }

  &.rounded-square {
    border-radius: 10px;
  }
  &.rounded-square-5 {
    border-radius: 5px;
  }

  &.avatar-padding {
    background-color: #ffffff;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.avatar-border {
    border: 1px solid rgba(196, 196, 196, 0.5);
  }

  &.avatar-shadow-sm {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  &.avatar-shadow {
    box-shadow: 0px 3.9px 7.8px rgba(0, 0, 0, 0.2);
  }

  &.avatar-shadow-lg {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  }


}

//Phone Input
.react-tel-input {
  .form-control {
    border: 1px solid #dee2e6 !important;
    font-size: 14px !important;
    line-height: 32px !important;
    background-color: #ffffff !important;
    border-radius: 20px !important;
    width: 100% !important;
    height: 47px !important;
  }
  .flag-dropdown{
    border-radius: 20px 0px 0px 20px !important;
    background-color: $white !important;
    border: 1px solid #dee2e6 !important;

  }
}

//Input

.form-control {
  border: 1px solid $gray-300;
  font-size: 14px;
  line-height: 32px;
  background-color: $white !important;
  border-radius: 20px;

  &:focus {
    border-color: $primary;
    box-shadow: none;
  }

  &[readonly] {
    background-color: $white !important;
  }

  &:disabled {
    background-color: $gray-300;
  }

  &.form-control-error {
    border: 1px solid $red;
  }
}
.form__form-group-description{
  color: $muted;
  font-size: 14px;
  font-weight: 300;
}

// Loader Button

.ball {
  --size: 10px;
  --timing: 0.7s;
  --displace: 40px;
  border-radius: 50%;
  width: var(--size);
  height: var(--size);
  background: #000;
  margin-right: var(--displace);
  animation: animation321 var(--timing) infinite alternate cubic-bezier(0.68, -0.55, 0.265, 1.55);

  &.ball-sm {
    --size: 7px;
    --timing: 0.7s;
    --displace: 12px;
  }
}

@keyframes animation321 {
  0% {
    background: $pink;
    transform: translateX(var(--displace));
  }

  100% {
    background: $success;
    transform: translateX(00px);
  }
}

//Loader Spinner
.loader-spinner {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #e75480;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader-spinner::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-bottom: 4px solid #1F2428;
  border-left: 4px solid transparent;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
// Loading Process Bar

.loading-bar {
  width: 100%; 
  height: 20px; 
  background-color: #ddd; 
  position: relative;
  overflow: hidden;
  border-radius: 20px;
}

.loading-progress {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 0%; 
  background-color: $success;
  animation: load 8s linear forwards; 
}

@keyframes load {
  from {
    width: 0%; 
  }
  to {
    width: 100%; 
  }
}


//Day Picker
.date-picker {
  background-color: $pink-lighter;
  border-radius: 20px;
}
.rdp-caption_label{
  z-index: 0;
}
.rdp-cell {
  .my-selected {
  background-color: $primary;
  color: $white;
  height: 32px;
    width: 32px;
    border-radius: 50%;
    font-size: 14px;
}
  .rdp-day {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    font-size: 14px;
  }
}



//Table

.table-container{
  overflow-x: scroll;
  .table-wrapper{
    table{
      thead {
    
        tr {
          th {
            padding: 14px 14px 14px 14px;
            background-color: $white-dark;
            &:first-child  {
              border-radius: 8px 0px 0px 8px;
            }
            &:last-child{
              border-radius: 0px 8px 8px 0px;
            }
          }
          
        }
      }
    }
    td{
      padding: 14px 14px 14px 14px;
    }
  }
}


//Page Header Search

.page__header_search {
  width: 350px !important;
  background-color: $white !important;
  border: 1px solid #dee2e6;
  padding: 10px 24px 10px 65px;
  border-radius: 20px;
  background-image: url("https://tsignet.com:8000/api/media/public/icons/topbar-search.svg");
  background-position: 24px 8px;
  background-repeat: no-repeat;

  @media (max-width: 600px) {
    width: 100% !important;
  }

  &::-webkit-input-placeholder {
    font-size: 14px;
    font-weight: 300;
    color: #797979;
  }

  &::-moz-placeholder {
    font-size: 14px;
    font-weight: 300;
    color: #797979;
  }

  &:-ms-input-placeholder {
    font-size: 14px;
    font-weight: 300;
    color: #797979;
  }

  &:-moz-placeholder {
    font-size: 14px;
    font-weight: 300;
    color: #797979;
  }
}
// Time Picker 

.react-time-picker__inputGroup{
  display: flex;
  align-items: center;
  gap: 8px;
}
.react-time-picker__inputGroup__divider{
  font-weight: 700;
  font-size: 18px;
}
.react-time-picker__inputGroup__hour{
  width: 55px !important;
}
.react-time-picker__inputGroup__minute{
  width: 65px !important;
}
.react-time-picker__wrapper{
  border: 1px solid #dee2e6 !important;
  border-radius: 20px;
}
.react-time-picker__inputGroup__input{
  height: 46px !important;
  border-radius: 20px;
  padding-left: 10px;
  border: none !important;

  // border: 1px solid #dee2e6 !important;
  outline: $primary;
}
.react-time-picker__clear-button,
.react-time-picker__clock-button{
  display: none !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.form-file {
  position: relative;
  width: 100%;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;

  .form-file-control {
    opacity: 0;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: pointer;

    &:disabled {
      cursor: auto;
    }
  }

  ul {
    position: relative;
    z-index: 99;
  }

  li {
    background: #f8f9fa;
    border-radius: 6px;
  }

  img {
    width: 50px;
  }
}

//React Time Pickerr
.react-datepicker-wrapper{
  width: fit-content;
  .react-datepicker__input-container{
    input{
      border: 1px solid #dee2e6;
      font-size: 14px;
      line-height: 32px;
      background-color: #ffffff !important;
      border-radius: 20px;
      padding-left: 12px;
      height: 44px;
    }
  
  }
}

//Loader

.preloader-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  opacity: 1;
}

.preloader {

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f9d4df;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    .spin {
      width: 100px; /* Resmin genişliği */
      height: 100px; /* Resmin yüksekliği */
      border-radius: 50%;
      background-repeat: no-repeat;
      background-image: url("https://www.sehrimbu.com/images/logo_2.png");
      background-size: contain; /* Resmi genişliğe ve yüksekliğe sığdır */
      background-position: center; /* Resmi ortala */
      position: absolute;
      left: 0; /* Sol ortadan sabitlenmesi için */
      animation: directionMove 3s infinite linear;
    }

    .spin::before,
    .spin::after {
      display: none;
      content: "";
      width: inherit;
      height: inherit;
      border: inherit;
      border-radius: inherit;
    }

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(-360deg);
      }
    }
  }
}

@keyframes directionMove {
  0%, 100% { transform: translate(0, 0); }
  20% { transform: translate(0, -20px); } /* Yukarı */
  40% { transform: translate(0, 20px); }  /* Aşağı */
  60% { transform: translate(-20px, 0); } /* Sola */
  80% { transform: translate(20px, 0); }  /* Sağa */
}