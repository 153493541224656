.root {
  display: flex;
  background-color: $white;
  height: 74px;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  padding-left: 0px;
  border-radius: 8px;
}
.search{

}
.actions{
  display: flex;
  align-items: center;
  gap: 24px;
}
.mobile__menu-icon{
  display: none;
  @media(max-width: 575px){
    display: block;
    z-index: 1000;
    filter: drop-shadow(3px 3px 3px rgba(231, 84, 128, 0.5));
  }
 
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  gap: 36px;
  z-index: 1000;
  filter: drop-shadow(3px 3px 3px rgba(231, 84, 128, 0.5));
}

.iconContainer::before {
  content: ''; 
  position: absolute;
  left: 50%;
  transform: translateX(-50%); 
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: rgba(231, 84, 128, 0.6); 
}

