.my_place__reservation{
  .date-center{
    left: 50%;
    transform: translate(-50%, 0);
    .date-picker {
      border: 1px solid $primary;
      color: $primary;
    }
  }

}

.activity_create{
  .form-check-input{
    width: 25px !important;
    height:  25px !important;
  }
}

.reservation-success-cart {
  width: 500px; 
  height: 500px;
  background-size: cover;
  background-position: center;
  position: relative;
  background-color: $white;
  border-radius: 8px;
}
.place-campaign-container{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 24px;
  .image-container{
    border-radius: 20px;
    width: 100%;
    height: 250px;
    img {
      object-fit: fill;
    }
  }

  .form-check-input.form-check-input:checked {
    background-color: $success;
    border-color: #ffffff;
    color: $primary;
    width: 25px;
    height: 25px;
    min-width: 25px;
    min-height: 25px;
    cursor: pointer;
}
  .form-check-input.form-check-input {
    background-color: $white;
    border-color: #ffffff;
    color: $primary;
    width: 25px;
    height: 25px;
    min-width: 25px;
    min-height: 25px;
    cursor: pointer;
}


}
