//
// Bootstrap-custom.scss
//

.row > * {
    position: relative;
  }
 
  .container {
    max-width: 1300px !important;
    width: 90% !important;
  }
  @media only screen and (max-width: 991px) {
    .container {
      width: 100% !important;
    }
  }
  
  @each $heading, $size in $heading-font-sizes {
    #{$heading},
    .#{$heading} {
      font-size: $size !important;
    }
  }
  
  @each $heading, $size in $display-font-sizes {
    .#{$heading} {
      font-size: $size !important;
    }
  }
  
  @each $name, $value in $colors {
    .bg-#{$name} {
      background-color: #{$value} !important;
    }
    .bg-soft-#{$name} {
      background-color: rgba($value, 0.1) !important;
      border: 1px solid rgba($value, 0.1) !important;
      color: #{$value} !important;
    }
    .text-#{$name} {
      color: #{$value} !important;
    }
    a {
      transition: all 0.5s ease;
      &.text-#{$name} {
        &:hover,
        &:focus {
          color: darken($value, 5%) !important;
        }
      }
    }
  }
  
  .d-flex {
    .flex-1 {
      flex: 1;
    }
  }
  
  .bg-black {
    background: $black !important;
  }
  
  //li (list inline item)
  .list-inline-item:not(:last-child) {
    margin-right: 0px;
    margin-bottom: 5px;
  }
  
  // Rounded
  .rounded {
    border-radius: 6px !important;
  }
  .rounded-md {
    border-radius: 10px !important;
  }
  .rounded-lg {
    border-radius: 20px !important;
  }
  .rounded-xl {
    border-radius: 60px !important;
  }
  .rounded-top {
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
  }
  .rounded-left {
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
  }
  .rounded-bottom {
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
  }
  .rounded-right {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
  }
  
  //Border
  .border {
    border: 1px solid $gray-200 !important;
  }
  .border-dark {
    border: 1px solid $gray-500 !important;
  }
  .border-top {
    border-top: 1px solid $gray-200 !important;
  }
  .border-bottom {
    border-bottom: 1px solid $gray-200 !important;
  }
  .border-left {
    border-left: 1px solid $gray-200 !important;
  }
  .border-right {
    border-right: 1px solid $gray-200 !important;
  }
  .border-primary {
    border: 1px solid $primary !important;
  }

  .border-bottom-primary {
    border-bottom: 1px solid $primary !important;
  }
  
  
  //Small
  .small,
  small {
    font-size: 90%;
  }
  
  //card
  .card-wrapper {
    background: #F4F8FB;
    border-radius: 17px;
    padding: 40px 25px 32px 25px;
  }
  
  .card {
    border: 0;
    border-radius: 20px;
  
    .card-body {
      background-color: $white;
      padding: 2rem;
      border-radius: 8px;
    }
  
    &.card-shadow {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    }
    &.card-border {
      border: 1px solid $content-lighter;
    }
  
    &.card-report-activity {
      background-size: cover;
      max-height: 800px;
      min-height: 200px;
      overflow-y: auto;
  
      .card-body {
        background: transparent !important;
      }
    }
  }
  