.phone-display {
  width: 325px; /* iPhone X genişliği gibi */
  height: 627px; /* iPhone X yüksekliği gibi */
  border-radius: 50px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.3);
  background: $primary;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: sticky;
  top: 0;
}

.phone-screen {
  width: 90%; 
  height: 85%;
  background-color: #f0f0f0;
  overflow-y: auto;
}

.phone-header {
  position: absolute;
  top: 1rem;
  width: 100%;
  .camera{
    width: 10px;
    height: 10px;
    min-width: 10px;
    min-height: 10px;
    border-radius: 200px;
    background-color: #000;
    position: relative;
    left: 50%;

  }
}

.phone-footer {
  position: absolute;
  bottom: 1rem;
  width: 100%;
  text-align: center;
  font-size: 10px;
  color: #333;
}

.phone-button {
  position: absolute;
  left: 50%;
  bottom: 2rem;
  width: 60px;
  height: 6px;
  background: $primary-light;
  border-radius: 10px;
  transform: translateX(-50%);
}
