@each $name,
$value in $colors {
  .tab-#{$name} {
    background-color: #{$value} !important;
    border: 1px solid #{value};
    color: $white !important;
    border-radius: 20px;
    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus {
      background-color: darken($value, 10%) !important;
      border-color: darken($value, 10%) !important;
      color: $white !important;
    }
  }

  .tab-soft-#{$name} {
    background-color: rgba($value, 0.1) !important;
    border: 1px solid rgba($value, 0.1) !important;
    color: #{$value} !important;

    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus {
      background-color: #{$value} !important;
      border-color: #{$value} !important;
      color: $white !important;
    }
  }

  .tab-outline-#{$name} {
    border: 1px solid #{$value};
    color: #{$value};
    background-color: transparent;

    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus,
    &:not(:disabled):not(.disabled):active {
      background-color: #{$value};
      border-color: #{$value};
      color: $white !important;
    }
  }

  .tab-focus-none {
    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }
}
.tab {
  border-radius: 20px ;
  outline: none;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s;
  cursor: pointer;
  width: 200px;
  min-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.tab-wrapper{
  overflow-x: scroll;
}