
// Color
$white:                     #ffffff ;
$white-dark:                   #f7f7f7;
$black:                     #0000;  
$muted:                     #a0b1b1;
$primary:                   #1F2428; 
$primary-light-dark:        #bec3c9;
$primary-light:             #F3F4F5;
$primary-dark:              #000;
$success:                   #59CE8F;
$success-light:               #def5e9;
$danger:                    #FF0000;
$content:                   #32334b;
$pink:                      #e75480;
$pink-light:                 #f9d4df;
$pink-lighter:                #fdf4f7;
$yellow:                        #ffb700;



// Gray
$gray-100:                  #f8f9fa;
$gray-200:                  #e9ecef;
$gray-300:                  #dee2e6;
$gray-400:                  #ced4da;
$gray-500:                  #adb5bd;
$gray-600:                  #6c757d; 
$gray-700:                  #495057;
$content-light:             #646d82;
$content-lighter:           #D9D9D9;

// Shadow

$shadow:                    0 0 3px rgba($primary,.15);
$shadow-s:                  0 1px 4px #0000000d;
$shadow-xs:                 0 5px 13px rgba($primary, 0.05);
$shadow-md:                 0 5px 13px rgba($primary, .2);
$shadow-lg:                 0 10px 25px rgba($primary, 0.15);


$footer:                    lighten($black, 6%);

// Base font
$font-size-base:            16px;
$font-family-base:          'Poppins', sans-serif;
$font-family-secondary:     'Poppins', sans-serif;



$colors: (
    "white": $white,
    "white-dark": $white-dark,
    "black": $black,
    "primary": $primary,
    "primary-light": $primary-light,
    "primary-light-dark": $primary-light-dark,
    "primary-dark": $primary-dark,
    "muted": $muted,
    "success": $success,
    "success-light": $success-light,
    "danger": $danger,
    "content": $content,
    "content-light": $content-light,
    "content-lighter": $content-lighter,
    "pink": $pink,
    "pink-light": $pink-light,
    "pink-lighter": $pink-lighter,
    "yellow": $yellow
);


$heading-font-sizes: (
    "h1": 42px,
    "h2": 36px,
    "h3": 30px,
    "h4": 24px,
    "h5": 20px,
    "h6": 16px,
);

$display-font-sizes: (
    "display-1": 80px,
    "display-2": 72px,
    "display-3": 64px,
    "display-4": 56px,
);



