/* Sidebar Global CSS */

.sidebar {
  position: fixed;
  top: 11px;
  left: 11.5px;
  z-index: 99;
  height: 97.5vh;
  width: 230px;
  padding-top: 15px;
  transition: transform 0.3s, width 0.3s;
  transform: translateX(calc(-100% - 20px));
  background-color: $primary;
  backdrop-filter: blur(6px);
  border-radius: 20px;
  z-index: 1;

}
.sidebar__logout-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: rgba(112, 112, 112, 0.05);
  color: rgba(255, 255, 255, 0.4);
  margin-top: 24px;
  cursor: pointer;
  font-size: 12px !important;
}
.sidebar_logout-btn .sidebar_link-title {
  font-size: 12px !important;
}
.sidebar a {
  display: block;
}
.sidebar.sidebar--show {
  transform: translateX(0);
}
.sidebar.sidebar--show .sidebar__back {
  display: block;
}
.sidebar__back {
  height: 100%;
  width: 100vw;
  position: absolute;
  display: none;
  background: transparent;
  border: none;
}
.sidebar_link-active .sidebar_link:before {
  opacity: 0;
}
.sidebar_link-active .sidebar_link-title {
  color: #fff;
}
.sidebar__scroll {
  width: 230px;
}
.sidebar__scroll > div {
  height: calc(100vh - 60px);
}
.sidebar__scroll .scrollbar-track.scrollbar-track-y {
  width: 2px;
  margin-right: 3px;
}
.sidebar__scroll .scrollbar-track.scrollbar-track-x {
  display: none !important;
}
.sidebar__scroll .scrollbar-thumb {
  opacity: 0.3;
  width: 5px;
}
.sidebar__content {
  padding-top: 15px;
  height: 100%;
  overflow: auto;
}
.sidebar__content > div:last-child {
  width: 4px !important;
}
.sidebar__content > div:last-child div {
  transition: height 0.3s;
  opacity: 0.52;
}
.sidebar__block {
  padding: 15px 0;
}
.sidebar__block:last-child {
  border: none;
}
.sidebar__link {
  height: 56px;
  width: 100%;
  transition: all 0.3s;
  position: relative;
  cursor: pointer;
  display: flex;
  padding: 11px 20px;
  overflow: hidden;
  background: transparent;
  border: none;
  color: white !important;
  overflow-x: none;
  align-items: center !important;
}
.sidebar__link:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(252, 252, 252, 0.1);
  opacity: 0;
  transition: all 0.3s;
}
.sidebar__link:hover {
  text-decoration: none;
  background-color: rgba(252, 252, 252, 0.1);
  color: rgb(255, 255, 255);
}
.sidebar__link:hover p {
  color: #ffffff !important;
}
.sidebar_link:hover .sidebar_category-icon {
  color: #ffffff !important;
}
.sidebar_link:hover .sidebar_link-icon {
  color: #ffffff !important;
}
.sidebar__link:hover:before {
  opacity: 1;
}
.sidebar__link p {
  position: absolute;
  left: 34px;
  width: 160px;
  transition: left 0.3s;
  top: 50%;
  transform: translateY(-50%);
}
.sidebar__link-title {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  position: relative;
  display: flex;
  padding-left: 10px;
  color: #c7c0bd;
}

.sidebar__icon {
  stroke: #c7c0bd;
}
.sidebar_link-active .sidebar_icon {
  stroke: #fff;
}
.sidebar_arrow__icon {
  stroke: #fff;
}

.sidebar__link-icon {
  margin-right: 10px;
  font-size: 15px;
  line-height: 15px;
  color: #ffffff;
  width: 15px !important;
  display: flex;
  justify-content: center;
}
.sidebar_submenu .sidebar_link {
  padding-left: 53px !important;
  display: flex !important;
  align-items: center !important;
}
.sidebar_submenu .sidebar_link p {
  left: 53px !important;
}
.sidebar_submenu .sidebar_link p {
  left: 65px !important;
}
.sidebar_submenu .sidebar_link {
  display: flex;
  align-items: center;
}
.sidebar__category-icon {
  position: absolute;
  right: 15px;
  font-size: 14px;
  line-height: 14px;
  margin-top: 4px;
  opacity: 1;
  transition: opacity 0.5s 0.2s, transform 0.3s;
  color: #fff;
}
.sidebar__link-badge {
  width: 26px;
  height: 14px;
  background-color: #d9534f; /* Replace $danger with actual color value */
  font-size: 8px;
  font-weight: 400;
  padding: 2px;
  margin-left: 5px;
  line-height: 9px;
  position: relative;
  text-transform: uppercase;
  border-radius: 7px;
}
.sidebar__link-badge span {
  position: absolute;
  left: 0;
  top: 3px;
  width: 26px;
  text-align: center;
}
.sidebar__wrapper--desktop {
  display: none;
}
.sidebar_category-wrap--open .sidebar_category-icon {
  transform: rotate(90deg);
}
.sidebar__category-new {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  top: -3px;
  display: block;
  margin-left: 5px;
  background: #d9534f; /* Replace $danger with actual color value */
}
.sidebar__user--avatar-rounded {
  width: 90px;
  height: 90px;
  min-width: 90px;
  min-height: 90px;
  border-radius: 2000px
}
.sidebar__user--avatar-square {
  width: 55px;
  height: 55px;
  min-width: 55px;
  min-height: 55px;
}
.sidebar__user-info {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 6px;
  margin-top: 24px;
  flex-direction: column;
}
.siderbar__user-name {
  font-size: 14px;
  color: white;
  font-weight: 700
}
.siderbar__user-email {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
}
@media screen and (min-width: 576px) {
  .sidebar {
      transform: translateX(0);
  }
  .sidebar.sidebar--no-desktop {
      transform: translateX(calc(-100% - 20px));
  }
  .sidebar.sidebar--no-desktop.sidebar--show {
      transform: translateX(0);
  }
  .sidebar__wrapper--mobile {
      display: block;
  }
  .sidebar.sidebar--collapse .sidebar__scroll,
  .sidebar.sidebar--collapse .sidebar__content {
      width: 55px !important;
      overflow: visible !important;
      transition: width 0.3s;
  }
  .sidebar.sidebar--collapse .sidebar__submenu {
      padding: 0 0 15px 0;
      transition: 0s;
  }
  .sidebar.sidebar--collapse .sidebar__submenu-wrap {
      position: absolute;
      left: 55px;
      width: 0;
      transition: 0.3s;
      overflow: hidden;
  }
  .sidebar.sidebar--collapse .sidebar_submenu-wrap .sidebar_link {
      width: 185px;
      padding-left: 15px;
  }
  .sidebar.sidebar--collapse
      .sidebar__submenu-wrap
      .sidebar__submenu-wrap
      .sidebar__link {
      padding-left: 30px;
  }
  .sidebar--collapse {
      width: 55px !important;
      overflow: visible !important;
      z-index: 2;
  }
  .sidebar.sidebar--collapse .sidebar__link {
      overflow: hidden;
      width: 55px !important;
      background-color: transparent;
  }
  .sidebar.sidebar--collapse .sidebar__link p {
      position: absolute;
      left: 70px;
      width: 160px;
  }
  .sidebar.sidebar--collapse .sidebar__link:hover {
      width: 240px;
  }
  .sidebar.sidebar--collapse .sidebar__category-icon {
      opacity: 0;
      transition: opacity 0s;
  }
  .sidebar.sidebar--collapse .scrollbar-track.scrollbar-track-y {
      margin-right: 188px;
  }
  .sidebar__wrapper--desktop {
      display: block;
  }
  .sidebar__wrapper--mobile {
      display: none;
  }
}
@media screen and (min-width: 992px) {
  .sidebar.sidebar--no-desktop {
      transform: translateX(0);
      display: none;
  }
}
.card-header span {
  color: #34445e;
}
.sidebar__button {
  width: 50px;
  height: 50px;
  display: flex;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: 0.3s;
}
.sidebar__button:hover {
  outline: none;
  border-radius: 50px;
  background-color: rgba(252, 252, 252, 0.1);
}
.sidebar__button:focus {
  background-color: transparent;
  border-radius: 50px;
}
.sidebar__button:active {
  border: 0.5px solid #fff;
}
.sidebar-opacity-bg {
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(2px);
  z-index: 99999;
}

/* MAIN CONTAINER GLOBAL CSS */

.container {
  padding-right: 0px;
}

@media screen and (min-width: 768px) {
  .container {
      width: 100%;
      max-width: 1850px;
  }
}


.container__wrap {
  padding-left: 0;
  padding-top: 10px;
  padding-bottom: 30px;
  min-height: 100vh;
  transition: padding-left 0.3s;
  padding-right: 2rem;
  background: rgba(252, 247, 245, 1);
}

@media screen and (min-width: 576px) {
  .container__wrap {
      padding-left: 265px;
  }
}

@media screen and (max-width: 415px) {
  .container__wrap {
      padding-left: 0px !important;
      padding-right: 1rem !important;
  }
}

.layout.layout--collapse + .container__wrap {
  padding-left: 0;
}

@media screen and (min-width: 576px) {
  .layout.layout--collapse + .container__wrap {
  }
}

@media screen and (min-width: 576px) {
  .layout.layout--top-navigation + .container__wrap {
      padding-left: 0;
      padding-right: 0;
  }
}
@media (max-width: 576px) {
  .container__wrap {
    padding-right: 0rem !important
  }
}

.ct {
  gap: 42px;
  display: flex;
  flex-wrap: wrap;
}
.div1 {
  background-color: var(--color-brand-primary);
  width: 250px;
  height: 250px;
  border-radius: 20px;
}

.div2 {
  background-color: var(--color-brand-tertiary);
  width: 250px;
  height: 250px;
  border-radius: 20px;
}

.div3 {
  background-color: #1f2428;
  width: 250px;
  height: 250px;
  border-radius: 20px;
}

.div4 {
  background-color: #94c279;
  width: 250px;
  height: 250px;
  border-radius: 20px;
}
.div5 {
  background-color: #ffd0bd;
  width: 250px;
  height: 250px;
  border-radius: 20px;
}
.logo__container{
  margin-left: 3rem;
}
.sidebar__button-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
}
.sidebar__button-icon:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 200px;
}

.sidebar__button-icon-mobil {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
}
.sidebar__button-icon-mobil:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 0px;
}

//MOBILE SIDEBAR

.sidebar__mobile-root{
  position: fixed !important;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 80%;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(20px);
  z-index: 1001;
}



@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.sidebar__mobile-root.open {
  animation: slideIn 0.5s forwards;
}

.sidebar__mobile-root.close {
  animation: slideOut 0.5s forwards;
}
