.auth-section:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(231, 84, 128, 0.2);
  pointer-events: none;
}
.auth-section-signup:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  pointer-events: none;
}
.auth-section-signup {
 background-color: $primary;

 .form-control {
  padding: 12px 13px !important;
  border: 1px solid #dedede !important;
  border-radius: 50px !important;
  font-size: 14px;
  background-color: #ffffff !important;

  &::-webkit-input-placeholder {
    font-size: 14px;
    color: #9ea2b1;
  }
  &::-moz-placeholder {
    font-size: 14px;
    color: #9ea2b1;
  }
  &:-ms-input-placeholder {
    font-size: 14px;
    color: #9ea2b1;
  }
  &:-moz-placeholder {
    font-size: 14px;
    color: #9ea2b1;
  }
}

.form-control-password {
  padding-right: 35px !important;
}

.form-control-password-preview {
  position: absolute;
  top: 16px;
  right: 14px;
  color: #333333a8;
  cursor: pointer;

  &.active {
    color: #333333;
  }
}

button {
  padding: 12px 30px;
  margin-top: 2rem;
}
}
.auth-section {
  width: 100%;
  height: 100vh;
  min-height: 700px;
  background-image: url("../images/auth/auth-background.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;

  @media (max-height: 750px) {
    height: auto;
    padding: 180px 0px;
  }

  .form-control {
    padding: 12px 13px !important;
    border: 1px solid #dedede !important;
    border-radius: 50px !important;
    font-size: 14px;
    background-color: #ffffff !important;

    &::-webkit-input-placeholder {
      font-size: 14px;
      color: #9ea2b1;
    }
    &::-moz-placeholder {
      font-size: 14px;
      color: #9ea2b1;
    }
    &:-ms-input-placeholder {
      font-size: 14px;
      color: #9ea2b1;
    }
    &:-moz-placeholder {
      font-size: 14px;
      color: #9ea2b1;
    }
  }

  .form-control-password {
    padding-right: 35px !important;
  }

  .form-control-password-preview {
    position: absolute;
    top: 16px;
    right: 14px;
    color: #333333a8;
    cursor: pointer;

    &.active {
      color: #333333;
    }
  }

  button {
    padding: 12px 30px;
    margin-top: 2rem;
  }
}

.login-section {
  .login-card {
    width: 515px;
    height: auto;
    border-radius: 20px;
    margin: 0 10px;
    background-color: rgba(255, 255, 255, 1);
    backdrop-filter: blur(6px) ;
    .login-card-bg {
     
    }
    .card-body {
      padding: 55px 50px 35px 50px;
    background-color: transparent;

    }
  }
}

.signup-section {
  @media (max-height: 1045px) {
    height: auto;
    padding: 75px 0px;
  }

  .wrapper {
    max-width: 430px;
    width: 100%;

    @media (max-width: 1300px) {
      max-width: 375px !important;
    }

    @media (max-width: 992px) {
      max-width: 100% !important;
    }
  }

  .signup-card {
    width: 1280px;
    height: auto;
    border-radius: 20px;
    margin: 0 10px;

    .card-body {
      padding: 65px 150px;

      @media (max-width: 768px) {
        padding: 50px 100px;
      }
      @media (max-width: 576px) {
        padding: 30px 60px;
      }
    }

    .card-image {
      width: 550px;
      height: auto;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      background-image: url("../images/auth/auth-background.jpg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;

      @media (max-width: 1300px) {
        width: 400px;
      }

      @media (max-width: 992px) {
        display: none;
      }
    }
  }

}

$strength-colors: (darkred, orangered, orange, yellowgreen, green);
$strength-gap: 6px;

.strength-meter {
  height: 3px;
  background: #ddd;
  border-radius: 2px;
  overflow: hidden;
  position: absolute;
  top: -8px;
  left: 0;
  display: flex;
  width: 98%;
  left: 1%;

  &:before,
  &:after {
    content: "";
    height: inherit;
    background: transparent;
    display: block;
    border-color: #fff;
    border-style: solid;
    border-width: 0 $strength-gap 0;
    position: absolute;
    width: calc(20% + #{$strength-gap});
    z-index: 2;
  }
  &:before {
    left: calc(20% - #{($strength-gap / 2)});
  }
  &:after {
    right: calc(20% - #{($strength-gap / 2)});
  }
}

.strength-meter-fill {
  background: transparent;
  height: inherit;
  position: absolute;
  width: 0;
  // border-radius: inherit;
  transition: width 0.5s ease-in-out, background 0.25s;
  @for $i from 1 through 5 {
    &[data-strength="#{$i - 1}"] {
      width: (20% * $i);
      background: nth($strength-colors, $i);
    }
  }
}
.form-control-password-preview {
  position: absolute;
  top: 20px;
  right: 14px;
  color: $muted;
  cursor: pointer;

  &.active {
    color: $primary;
  }
}
